// Notes!
// Sass color functions, "darken" and "lighten" are automatically replaced.

// basics.scss
body {
  background: var(--background-color);
}

.simple_form .button.button-tertiary {
  color: $highlight-text-color;

  &:hover,
  &:focus,
  &:active {
    color: $white;
  }
}

.status-card__actions button,
.status-card__actions a {
  color: rgba($white, 0.8);

  &:hover,
  &:active,
  &:focus {
    color: $white;
  }
}

// Change default background colors of columns
.column > .scrollable,
.getting-started,
.column-inline-form,
.regeneration-indicator {
  background: $white;
  border: 1px solid lighten($ui-base-color, 8%);
  border-top: 0;
}

.error-column {
  border: 1px solid lighten($ui-base-color, 8%);
}

.column > .scrollable.about {
  border-top: 1px solid lighten($ui-base-color, 8%);
}

.about__meta,
.about__section__title,
.interaction-modal {
  background: $white;
  border: 1px solid var(--background-border-color);
}

.rules-list li::before {
  background: $ui-highlight-color;
}

.directory__card__img {
  background: lighten($ui-base-color, 12%);
}

.filter-form {
  background: $white;
  border-bottom: 1px solid lighten($ui-base-color, 8%);
}

.column-back-button,
.column-header {
  background: $white;
  border: 1px solid lighten($ui-base-color, 8%);

  @media screen and (max-width: $no-gap-breakpoint) {
    border-top: 0;
  }
}

.column-header__back-button,
.column-header__button,
.column-header__button.active,
.account__header {
  background: $white;
}

.column-header__button.active {
  color: $ui-highlight-color;

  &:hover,
  &:active,
  &:focus {
    color: $ui-highlight-color;
  }
}

.icon-button:disabled {
  color: darken($action-button-color, 25%);
}

.getting-started__footer a {
  color: $ui-secondary-color;
  text-decoration: underline;
}

.confirmation-modal__secondary-button,
.confirmation-modal__cancel-button,
.mute-modal__cancel-button,
.block-modal__cancel-button {
  color: lighten($ui-base-color, 26%);

  &:hover,
  &:focus,
  &:active {
    color: $primary-text-color;
  }
}

.column-subheading {
  border-bottom: 1px solid lighten($ui-base-color, 8%);
}

.getting-started,
.scrollable {
  .column-link {
    background: $white;
    border-bottom: 1px solid lighten($ui-base-color, 8%);

    &:hover,
    &:active,
    &:focus {
      background: $ui-base-color;
    }
  }
}

.getting-started .navigation-bar {
  border-top: 1px solid var(--background-border-color);
  border-bottom: 1px solid var(--background-border-color);

  @media screen and (max-width: $no-gap-breakpoint) {
    border-top: 0;
  }
}

.drawer__header,
.drawer__inner {
  background: $white;
  border: 1px solid lighten($ui-base-color, 8%);
}

.drawer__inner__mastodon {
  background: $white;
}

.search__input,
.search__popout,
.setting-text,
.report-dialog-modal__textarea,
.audio-player {
  border: 1px solid var(--background-border-color);
}

.report-dialog-modal .dialog-option .poll__input {
  color: $white;
}

.report-dialog-modal {
  background: darken($ui-base-color, 10%);
}

.report-dialog-modal__textarea {
  background: darken($ui-base-color, 10%);
}

.search__input {
  @media screen and (max-width: $no-gap-breakpoint) {
    border-top: 0;
    border-bottom: 0;
  }
}

.list-editor .search .search__input {
  border-top: 0;
  border-bottom: 0;
}

.upload-progress__backdrop {
  background: $ui-base-color;
}

// Change the background colors of statuses
.focusable:focus {
  background: lighten($white, 4%);
}

.detailed-status,
.detailed-status__action-bar {
  background: $white;
}

.account-gallery__item a {
  background-color: $ui-base-color;
}

// Change the text colors on inverted background
.actions-modal ul li:not(:empty) a.active,
.actions-modal ul li:not(:empty) a.active button,
.actions-modal ul li:not(:empty) a:active,
.actions-modal ul li:not(:empty) a:active button,
.actions-modal ul li:not(:empty) a:focus,
.actions-modal ul li:not(:empty) a:focus button,
.actions-modal ul li:not(:empty) a:hover,
.actions-modal ul li:not(:empty) a:hover button,
.simple_form .block-button,
.simple_form .button,
.simple_form button {
  color: $white;
}

.compare-history-modal .report-modal__target,
.report-dialog-modal .poll__option.dialog-option {
  border-bottom-color: lighten($ui-base-color, 4%);
}

.report-dialog-modal__container {
  border-top-color: lighten($ui-base-color, 4%);
}

.reactions-bar__item:hover,
.reactions-bar__item:focus,
.reactions-bar__item:active {
  background-color: $ui-base-color;
}

.reactions-bar__item.active {
  background-color: mix($white, $ui-highlight-color, 80%);
  border-color: mix(lighten($ui-base-color, 8%), $ui-highlight-color, 80%);
}

.media-modal__overlay .picture-in-picture__footer {
  border: 0;
}

.picture-in-picture__header {
  border-bottom: 0;
}

.announcements,
.picture-in-picture__footer {
  border-top: 0;
}

.icon-with-badge__badge {
  border-color: $white;
  color: $white;
}

.column-settings__hashtags .column-select__option {
  color: $white;
}

.dashboard__quick-access,
.focal-point__preview strong,
.admin-wrapper .content__heading__tabs a.selected {
  color: $white;
}

.flash-message.warning {
  color: lighten($gold-star, 16%);
}

.boost-modal__action-bar,
.confirmation-modal__action-bar,
.mute-modal__action-bar,
.block-modal__action-bar,
.onboarding-modal__paginator,
.error-modal__footer {
  background: darken($ui-base-color, 6%);

  .onboarding-modal__nav,
  .error-modal__nav {
    &:hover,
    &:focus,
    &:active {
      background-color: darken($ui-base-color, 12%);
    }
  }
}

.display-case__case {
  background: $white;
}

.embed-modal .embed-modal__container .embed-modal__html {
  background: $white;
  border: 1px solid var(--background-border-color);

  &:focus {
    border-color: lighten($ui-base-color, 12%);
    background: $white;
  }
}

.react-toggle-track {
  background: $ui-primary-color;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background: lighten($ui-primary-color, 10%);
}

.react-toggle.react-toggle--checked:hover:not(.react-toggle--disabled)
  .react-toggle-track {
  background: lighten($ui-highlight-color, 10%);
}

// Change the default color used for the text in an empty column or on the error column
.empty-column-indicator,
.error-column {
  color: $primary-text-color;
  background: $white;
}

// Change the default colors used on some parts of the profile pages
.activity-stream-tabs {
  background: $account-background-color;
  border-bottom-color: lighten($ui-base-color, 8%);
}

.nothing-here,
.page-header,
.directory__tag > a,
.directory__tag > div {
  background: $white;
  border: 1px solid var(--background-border-color);
}

.picture-in-picture-placeholder {
  background: $white;
  border-color: lighten($ui-base-color, 8%);
  color: lighten($ui-base-color, 8%);
}

.directory__tag > a {
  &:hover,
  &:active,
  &:focus {
    background: $ui-base-color;
  }
}

.batch-table {
  &__toolbar,
  &__row,
  .nothing-here {
    border-color: lighten($ui-base-color, 8%);
  }
}

.activity-stream {
  border: 1px solid var(--background-border-color);

  &--under-tabs {
    border-top: 0;
  }

  .entry {
    background: $account-background-color;

    .detailed-status.light,
    .more.light,
    .status.light {
      border-bottom-color: lighten($ui-base-color, 8%);
    }
  }

  .status.light {
    .status__content {
      color: $primary-text-color;
    }

    .display-name {
      strong {
        color: $primary-text-color;
      }
    }
  }
}

.accounts-grid {
  .account-grid-card {
    .controls {
      .icon-button {
        color: $darker-text-color;
      }
    }

    .name {
      a {
        color: $primary-text-color;
      }
    }

    .username {
      color: $darker-text-color;
    }

    .account__header__content {
      color: $primary-text-color;
    }
  }
}

.simple_form {
  .warning {
    box-shadow: none;
    background: rgba($error-red, 0.5);
    text-shadow: none;
  }

  .recommended {
    border-color: $ui-highlight-color;
    color: $ui-highlight-color;
    background-color: rgba($ui-highlight-color, 0.1);
  }

  input[type='text'],
  input[type='number'],
  input[type='email'],
  input[type='password'],
  input[type='url'],
  input[type='datetime-local'],
  textarea {
    background: darken($ui-base-color, 10%);
  }

  select {
    background: darken($ui-base-color, 10%)
      url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14.933 18.467' height='19.698' width='15.929'><path d='M3.467 14.967l-3.393-3.5H14.86l-3.392 3.5c-1.866 1.925-3.666 3.5-4 3.5-.335 0-2.135-1.575-4-3.5zm.266-11.234L7.467 0 11.2 3.733l3.733 3.734H0l3.733-3.734z' fill='#{hex-color(lighten($ui-base-color, 12%))}'/></svg>")
      no-repeat right 8px center / auto 14px;
  }
}

.compose-form .compose-form__warning {
  border-color: $ui-highlight-color;
  background-color: rgba($ui-highlight-color, 0.1);

  &,
  a {
    color: $ui-highlight-color;
  }
}

.status__content,
.reply-indicator__content {
  a {
    color: $highlight-text-color;
  }
}

.notification__filter-bar button.active::after,
.account__section-headline a.active::after {
  border-color: transparent transparent $white;
}

.activity-stream,
.nothing-here,
.directory__tag > a,
.directory__tag > div,
.card > a,
.page-header,
.compose-form,
.compose-form__warning {
  box-shadow: none;
}

.card {
  &__img {
    background: darken($ui-base-color, 10%);
  }

  & > a {
    &:hover,
    &:active,
    &:focus {
      .card__bar {
        background: darken($ui-base-color, 10%);
      }
    }
  }
}

.mute-modal select {
  border: 1px solid var(--background-border-color);
  background: $simple-background-color
    url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14.933 18.467' height='19.698' width='15.929'><path d='M3.467 14.967l-3.393-3.5H14.86l-3.392 3.5c-1.866 1.925-3.666 3.5-4 3.5-.335 0-2.135-1.575-4-3.5zm.266-11.234L7.467 0 11.2 3.733l3.733 3.734H0l3.733-3.734z' fill='#{hex-color(lighten($ui-base-color, 8%))}'/></svg>")
    no-repeat right 8px center / auto 16px;
}

.status__wrapper-direct {
  background-color: rgba($ui-highlight-color, 0.1);

  &:focus {
    background-color: rgba($ui-highlight-color, 0.15);
  }
}

.compose-form__actions .icon-button.active,
.dropdown-button.active,
.privacy-dropdown__option.active,
.privacy-dropdown__option:focus,
.language-dropdown__dropdown__results__item:focus,
.language-dropdown__dropdown__results__item.active,
.privacy-dropdown__option:focus .privacy-dropdown__option__content,
.privacy-dropdown__option:focus .privacy-dropdown__option__content strong,
.privacy-dropdown__option.active .privacy-dropdown__option__content,
.privacy-dropdown__option.active .privacy-dropdown__option__content strong,
.language-dropdown__dropdown__results__item:focus
  .language-dropdown__dropdown__results__item__common-name,
.language-dropdown__dropdown__results__item.active
  .language-dropdown__dropdown__results__item__common-name {
  color: $white;
}

.privacy-dropdown__option,
.language-dropdown__dropdown__results__item {
  &:hover,
  &:active {
    background: $ui-base-color;
  }
}

.compose-form .spoiler-input__input {
  color: lighten($ui-highlight-color, 8%);
}

.emoji-mart-search input,
.language-dropdown__dropdown .emoji-mart-search input,
.poll__option input[type='text'] {
  background: darken($ui-base-color, 10%);
}

.compose-form .autosuggest-textarea__textarea {
  background: darken($ui-base-color, 8%);
}

.compose-form {
  &__highlightable {
    border: 1px solid lighten($ui-base-color, 8%);

    .spoiler-input {
      .autosuggest-input {
        border-bottom: 1px solid lighten($ui-base-color, 8%);
        border-top: 1px solid lighten($ui-base-color, 8%);
      }
    }

    .autosuggest-textarea__textarea {
      background: $white;
    }
  }
}

.search__popout__menu__item {
  &:hover,
  &:active,
  &:focus,
  &.active {
    color: $white;

    mark,
    .icon-button {
      color: $white;
    }
  }
}

.inline-follow-suggestions {
  background-color: rgba($ui-highlight-color, 0.1);
  border-bottom-color: rgba($ui-highlight-color, 0.3);
}

.inline-follow-suggestions__body__scrollable__card {
  background: $white;
}

.inline-follow-suggestions__body__scroll-button__icon {
  color: $white;
}

a.sparkline {
  &:hover,
  &:focus,
  &:active {
    background: darken($ui-base-color, 10%);
  }
}

.dashboard__counters {
  & > div {
    & > a {
      &:hover,
      &:focus,
      &:active {
        background: darken($ui-base-color, 10%);
      }
    }
  }
}

.directory {
  &__tag {
    & > a {
      &:hover,
      &:focus,
      &:active {
        background: darken($ui-base-color, 10%);
      }
    }
  }
}

.strike-entry {
  &:hover,
  &:focus,
  &:active {
    background: darken($ui-base-color, 10%);
  }
}

.setting-text {
  background: darken($ui-base-color, 10%);
}

.autosuggest-account {
  .display-name__account {
    color: $dark-text-color;
  }
}

.notification-group--annual-report {
  .notification-group__icon,
  .notification-group__main .link-button {
    color: var(--indigo-3);
  }
}

@supports not selector(::-webkit-scrollbar) {
  html {
    scrollbar-color: rgba($action-button-color, 0.25)
      var(--background-border-color);
  }
}

.custom-scrollbars {
  ::-webkit-scrollbar-thumb {
    opacity: 0.25;
  }
}

// emoji_picker.scss
.emoji-mart-bar {
  &:first-child {
    background: darken($ui-base-color, 10%);
  }
}

.emoji-mart-search input {
  background: $ui-base-color;
}
